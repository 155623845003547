<template>
	<div>
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="progress-sec">
			<div :class="false?'progress-sec-finish-end':'progress-sec-finish'">
				<div class="progress-l" :style="'left:'+(-17.5 / 2 + ((677.5 / 2) * item.progress) / 100)+'px'"></div>
				<div class="flex-row align-center space-between progress-num-sec">
					<div class="progress-sec-con">已筹{{item.soldOutAmount}}元/{{item.surplusQuantity}}人支持</div>
					<div class="flex-row align-center" v-if="true  || item.progress >=100">
						<img src="../assets/market/fire_icon.png" class="fire-icon" />
						<div class="progress-sec-num">{{item.progress}}%</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LoginWrap from '@/components/Login.vue';
	export default {
		data() {
			return {
				showLogin: false,
				item:{
					progress:50,
					soldOutAmount:100,
					surplusQuantity:20,
					
				},
			}
		},
		methods: {

		},
		components: {
			LoginWrap
		},
		mounted() {
			const that = this;
			// let a = setInterval(() => {
			// 	that.progress = that.progress + 0.1
			// 	that.left = -17.5 / 2 + ((677.5 / 2) * that.progress) / 100
			// 	console.log(that.progress, that.left)
			// 	if (that.progress >= 100) {
			// 		clearInterval(a)
			// 	}
			// }, 50)
		}
	}
</script>

<style scoped="scoped">
	.progress-sec {
		width: 660px;
		height: 50px;
		background: #F3F3F3;
		border-radius: 10px;
		margin-top: 12px;
	}
	.progress-sec-finish {
		display: flex;
		width: 660px;
		height: 50px;
		background: linear-gradient(90deg, #F5F1FF 0%, #AF98FF 100%);
		position: relative;
		overflow: hidden;
	}

	.progress-l {
		position: absolute;
		background: linear-gradient(110deg, transparent 17.5px, #F3F3F3 0);
		left: -17.5px;
		top: 0px;
		width: 677.5px;
		height: 50px;
		border-radius: 0px 0 0 0px;
	}
	.progress-num-sec{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 660px;
		height: 50px;
		padding: 0 13px 0 17px;
		box-sizing: border-box;
	}
	.progress-sec-con {
		font-size: 20px;
		line-height: 50px;
		letter-spacing: 1px;
	}
	
	.fire-icon {
		width: 13px;
		height: 16px;
		margin-right: 11px;
	}
	
	.progress-sec-num {
		font-size: 20px;
		font-weight: bold;
		line-height: 28px;
		letter-spacing: 1px;
	}
</style>
